import React, { useContext } from 'react';

export enum ViewMode {
  Show,
  Edit,
}

const ViewModeContext = React.createContext<ViewMode>(ViewMode.Show);

export const useViewModeContext = () => useContext(ViewModeContext);

export default ViewModeContext;
