import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';
const SciNoPasswordPage = () => {
  return (
    <Card>
      <Title title='Register' />
      <CardContent>...</CardContent>
    </Card>
  );
};

export default SciNoPasswordPage;
