import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';

const SciDashboardPage = () => {
  return (
    <Card>
      <CardHeader title='Dies ist die Dashboard-Seite einer App der PPI Secure Cloud' />
      <CardContent>bitte entsprechend der Bedürfnisse der App anpassen...</CardContent>
    </Card>
  );
};

export default SciDashboardPage;
