import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import React from 'react';
import SciAppBar from './SciAppBar';
import ProfileContextProvider from '../contexts/profileContext/ProfileContext';
import SciMenu from './SciMenu';
import SciDashboardPage from '../pages/SciDashboardPage/SciDashboardPage';

const SciLayout = (props: LayoutProps) => {
  return (
    <ProfileContextProvider>
      <Layout {...props} menu={SciMenu} dashboard={SciDashboardPage} appBar={SciAppBar} />
    </ProfileContextProvider>
  );
};

export default SciLayout;
