import { CreateDialog } from '@react-admin/ra-form-layout';
import { RichTextInput } from 'ra-input-rich-text';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  RichTextField,
  SelectField,
  NumberField,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  SelectInput,
  ImageInput,
  Create,
  Edit,
} from 'react-admin';

export const ProdukteList = () => (
  <>
    <List hasCreate>
      <Datagrid rowClick='edit'>
        <TextField source='id' />
        <TextField source='title' />
        <RichTextField source='description' />
        <NumberField source='price' options={{ style: 'currency', currency: 'EUR' }} />
        <SelectField
          source='rating'
          choices={[
            { id: '1', name: 'schlecht' },
            { id: '2', name: 'unbefriedigend' },
            { id: '3', name: 'befriedigend' },
            { id: '4', name: 'gut' },
            { id: '5', name: 'hervorragend' },
          ]}
        />
        <ImageField source='picture.src' title='desc' />
      </Datagrid>
    </List>
  </>
);

export const ProdukteCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source='title' validate={required()} fullWidth />
      <RichTextInput source='description' validate={required()} fullWidth />
      <NumberInput source='price' />
      <SelectInput
        fullWidth
        source='rating'
        choices={[
          { id: '1', name: 'schlecht' },
          { id: '2', name: 'unbefriedigend' },
          { id: '3', name: 'befriedigend' },
          { id: '4', name: 'gut' },
          { id: '5', name: 'hervorragend' },
        ]}
      />
      <ImageInput source='picture' label='Related pictures'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const ProdukteEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source='id' />
      <TextInput source='title' validate={required()} fullWidth />
      <RichTextInput source='description' validate={required()} fullWidth />
      <NumberInput source='price' />
      <SelectInput
        fullWidth
        source='rating'
        choices={[
          { id: '1', name: 'schlecht' },
          { id: '2', name: 'unbefriedigend' },
          { id: '3', name: 'befriedigend' },
          { id: '4', name: 'gut' },
          { id: '5', name: 'hervorragend' },
        ]}
      />
      <ImageInput source='picture' label='Related pictures'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
