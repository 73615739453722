import { FormControl, Input, InputLabel, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useStageContext } from '../../../contexts/stageContext/StageContextProvider';
import { useTaskContext } from '../../../contexts/taskContext/TaskContextProvider';
import { useViewModeContext, ViewMode } from '../../../contexts/viewModeContext/ViewModeContext';

const DemoStage = () => {
  const { record, saveTaskData, updatePanelInformation } = useTaskContext();
  const { getCurrentStage } = useStageContext();
  const [testValue, setTestValue] = useState(
    record.taskData ? (record.taskData['test'] as string) : '',
  );
  const testValueRef = useRef<string>(testValue);
  const currentStage = getCurrentStage();
  const viewMode = useViewModeContext();

  // useRef needed to get the updated value of testValue
  useEffect(() => {
    testValueRef.current = testValue;
  }, [testValue]);

  // Triggers on unmount of the component
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      saveTaskData('test', testValueRef.current);
      console.log('Component unmount');
      updatePanelInformation('Test Wert', testValueRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // View Mode Show
  if (viewMode === ViewMode.Show) {
    return (
      <>
        <Typography variant='h5'>Show mode</Typography>
        <Typography variant='h5'>The stage name is: {currentStage?.name}</Typography>
        <Typography variant='h4'>The testValue is: {testValue}</Typography>
        <FormControl>
          <InputLabel htmlFor='my-input'>Test value:</InputLabel>
          <Input
            id='my-input'
            type='text'
            value={testValue}
            onChange={(event) => {
              setTestValue(event.currentTarget.value);
            }}
          />
        </FormControl>
      </>
    );
  } else {
    return (
      <>
        <Typography variant='h5'>Edit mode</Typography>
        <Typography variant='h5'>The stage name is: {currentStage?.name}</Typography>
        <Typography variant='h4'>The testValue is: {testValue}</Typography>
        <FormControl>
          <InputLabel htmlFor='my-input'>Test value:</InputLabel>
          <Input
            id='my-input'
            type='text'
            value={testValue}
            onChange={(event) => {
              setTestValue(event.currentTarget.value);
            }}
          />
        </FormControl>
      </>
    );
  }
};

export default DemoStage;
