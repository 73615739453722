import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  EditButton,
  List,
  SingleFieldList,
  TextField,
} from 'react-admin';

const TaskDefinitionList = (
  <List>
    <Datagrid>
      <TextField source='name' />
      <TextField source='id' />
      <ArrayField source='stages'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

export default TaskDefinitionList;
