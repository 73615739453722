import React from 'react';
import { CardContent, Grid } from '@mui/material';
import { Login, LoginForm } from 'react-admin';
import { Link } from 'react-router-dom';
import { UrlIFrameDialog } from '../../fields/UrlIFrameDialog';

const SciLoginPage = () => {
  return (
    <Login backgroundImage='https://source.unsplash.com/random/1600x900/daily'>
      <Grid container justifyContent='center'>
        <img src='ppi_logo_drawn_small.png' width='100' alt='PPI Secure Cloud' />
      </Grid>

      <CardContent sx={{ width: 350 }}>
        <LoginForm />
        <Grid container justifyContent='center'>
          <Link to='/nopassword'>Passwort?</Link>
        </Grid>
        <Grid container justifyContent='center'>
          <Link to='/register'>kein Account?</Link>
        </Grid>
        <br />
        <Grid container justifyContent='center'>
          <UrlIFrameDialog
            source='https://cms.ppi-cloud.de/ppi-secure-cloud/'
            label='PPI Secure Cloud'
            buttonLabel='PPI Secure Cloud?'
          />
          &nbsp;&nbsp;
          <UrlIFrameDialog source='https://www.ppi.de' label='PPI AG' buttonLabel='PPI AG?' />
        </Grid>
      </CardContent>
    </Login>
  );
};

export default SciLoginPage;
