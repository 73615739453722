import * as React from 'react';

import { Card, Box, Grid, Typography } from '@mui/material';
import { LocalesMenuButton, ToggleThemeButton } from 'react-admin';
import { SciDarkTheme, SciLightTheme } from '../../layout/themes/SciTheme';

const SciSettings = () => {
  return (
    <Card>
      <Box sx={{ p: 4 }}>
        <Typography variant='h6'> Sonstiges</Typography>
        <Box sx={{ p: 4 }}>
          <Grid container>
            <Grid item>Sprache:</Grid>
            <Grid item>
              <LocalesMenuButton
                languages={[
                  { locale: 'de', name: 'Deutsch' },
                  { locale: 'en', name: 'English' },
                  { locale: 'fr', name: 'Français' },
                ]}
              />
            </Grid>
          </Grid>
          <Box sx={{ p: 1 }}></Box>
          <Grid container>
            <Grid item>Theming:</Grid>
            <Grid item>
              <ToggleThemeButton darkTheme={SciDarkTheme} lightTheme={SciLightTheme} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

export default SciSettings;
