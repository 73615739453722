import AssignmentIcon from '@mui/icons-material/Assignment';
import DataArrayIcon from '@mui/icons-material/DataArray';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import { MenuItemList, MenuItemNode, IconMenu } from '@react-admin/ra-navigation';
import { CardContent, Typography } from '@mui/material';

const SciMenu = () => (
  <IconMenu>
    <IconMenu.Item name='produkte' to='/produkte' label='Produkte' icon={<HeadsetMicIcon />} />
    <IconMenu.Item name='dashboard' to='/' label='Dashboard' icon={<DashboardIcon />} />
    <IconMenu.Item name='tasks' to='/Tasks' label='Tasks' icon={<AssignmentIcon />} />
    <IconMenu.Item name='dataViews' to='/dataviews' label='Data Views' icon={<DataArrayIcon />} />
    <IconMenu.Item
      name='service'
      to='/service'
      label='Service'
      icon={<SettingsSystemDaydreamIcon />}>
      <CardContent>
        {/* to get consistent spacing */}
        <Typography variant='h6' gutterBottom>
          Service
        </Typography>
        {/* Note that we must wrap our MenuItemNode components in a MenuItemList */}
        <MenuItemList>
          <MenuItemNode name='menu.auditlog' to={'/events'} label='Audit Log' />
          <MenuItemNode
            name='artists.jazz'
            to={'/artists?filter={"type":"jazz"}'}
            label='Benutzer & Rollen'
          />
          <MenuItemNode name='menu.handbuch' to={'/handbuch'} label='Handbuch' />
          <MenuItemNode
            name='artists.classical'
            to={'/artists?filter={"type":"classical"}'}
            label='Support'
          />
          <MenuItemNode name='taskDefinitions' to={'/taskDefinitions'} label='Task Definitions' />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  </IconMenu>
);

export default SciMenu;
