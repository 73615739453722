import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useRecordContext } from 'react-admin';
import StageDefinition from '../../entities/businessEntities/StageDefinition';
import DemoStage from '../../entities/businessEntities/stages/DemoStage';

import { useLocation } from 'react-router-dom';
import TaskDefinition from '../../entities/businessEntities/TaskDefinition';

const GuiComponentTest = () => <Typography variant='h5'>Hello World</Typography>;
const EmptyComponent = () => <></>;
const ErrorComponent = () => <Typography variant='h6'>An error occured!</Typography>;
const stageComponentStore: Record<string, React.FC> = {
  'gui-component-1': GuiComponentTest,
  'gui-component-2': GuiComponentTest,
  'demo-stage': DemoStage,
  error: ErrorComponent,
};

type StageContextType = {
  currentStageIndex: number;
  setCurrentStageIndex(index: number): void;
  getCurrentStage(): StageDefinition | null | undefined;
  getCurrentStageGuiComponent(): React.FC;
  getAllStageComponents(): Record<string, string>[];
};

const StageContext = React.createContext({
  currentStageIndex: 0,
  setCurrentStageIndex: () => {
    return;
  },
  getCurrentStage: () => {
    return null;
  },
  getCurrentStageGuiComponent: () => EmptyComponent,
  getAllStageComponents: () =>
    Object.keys(stageComponentStore).map((key) => ({ id: key, name: key })),
} as StageContextType);

const StageContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const record: TaskDefinition = useRecordContext();
  const urlStage = new URLSearchParams(useLocation().search).get('stage');

  // Index of the stage to be currently displayed
  const [currentStageIndex, setCurrentStageIndex] = React.useState(() => {
    if (urlStage !== null) {
      const stageIndex = parseInt(urlStage);
      if (stageIndex !== undefined && stageIndex >= 0 && stageIndex < record.stages.length) {
        return stageIndex;
      }
    }
    if (!record.stages.some((stage) => stage.state != 2)) {
      return record.stages.length;
    } else if (record.stages.findIndex((stage) => stage.state == 1) !== -1) {
      return record.stages.findIndex((stage) => stage.state == 1);
    } else if (record.stages.findIndex((stage) => stage.state == 0) !== -1) {
      record.stages.findIndex((stage) => stage.state == 0);
    }
    return record.stages.length - 1;
  });

  const getCurrentStage = () => {
    if (currentStageIndex === record.stages.length) {
      return null;
    }
    if (
      currentStageIndex > record.stages.length ||
      record.stages[currentStageIndex] === undefined
    ) {
      return undefined;
    }
    return record.stages[currentStageIndex];
  };

  const getCurrentStageGuiComponent = () => {
    const currentStage = getCurrentStage();

    if (currentStage === null) {
      return EmptyComponent;
    }
    if (currentStage === undefined) {
      return stageComponentStore['error'];
    }
    return stageComponentStore[currentStage.guiComponent];
  };

  const stageContextData: StageContextType = {
    currentStageIndex,
    setCurrentStageIndex,
    getCurrentStage,
    getCurrentStageGuiComponent,
    getAllStageComponents: () =>
      Object.keys(stageComponentStore).map((key) => ({ id: key, name: key })),
  };
  return <StageContext.Provider value={stageContextData}>{children}</StageContext.Provider>;
};

export default StageContextProvider;
export const useStageContext = () => useContext(StageContext);
