import { FirebaseDataProvider, RAFirebaseOptions } from 'react-admin-firebase';
import firebaseProviderConfig from './firebaseProviderConfig.json';
import { RaRecord } from 'react-admin';
import FirebaseAuthProviderRbac from './SciFirebaseAuthProvider';
import { addEventsForMutations } from '@react-admin/ra-audit-log';

const firebaseDataProvider = FirebaseDataProvider(
  firebaseProviderConfig.firebaseConfig,
  firebaseProviderConfig.firebaseOptions as RAFirebaseOptions,
);

const auditLogDataProvider = addEventsForMutations(firebaseDataProvider, FirebaseAuthProviderRbac);

export const SciDataProvider = {
  ...auditLogDataProvider,
  getUserProfile() {
    const storedProfile = localStorage.getItem('profile');

    if (storedProfile) {
      return Promise.resolve({
        data: JSON.parse(storedProfile),
      });
    }

    // No profile yet, return a default one
    return Promise.resolve({
      data: {
        id: 'unique-id',
        fullName: '',
        avatar: '',
      },
    });
  },

  updateUserProfile(data: RaRecord) {
    return new Promise((resolve) => {
      localStorage.setItem(
        'profile',
        JSON.stringify({
          fullName: data.fullName,
          id: data.id,
          avatar: data.avatar,
        }),
      );
      resolve({ data });
    });
  },
};

export default SciDataProvider;
