import { Box, Button, Paper, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CircleIcon from '@mui/icons-material/Circle';

import StageDefinition from '../businessEntities/StageDefinition';
import { Link, useRecordContext } from 'react-admin';
import TaskDefinition from '../businessEntities/TaskDefinition';

type TaskStageViewProps = {
  index: number;
  stage: StageDefinition;
};

const TaskStageView = (props: TaskStageViewProps) => {
  const record: TaskDefinition = useRecordContext();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '5px 0', justifyContent: 'center' }}>
      <Link to={`${record.id}/show?stage=${props.index}`}>
        <Button
          startIcon={
            {
              0: <CircleIcon color='disabled' />,
              1: <ChangeCircleIcon color='warning' />,
              2: <CheckCircleIcon color='success' />,
            }[props.stage.state]
          }
          color='secondary'>
          {props.stage.name}
        </Button>
      </Link>
    </Box>
  );
};

export const TaskStagesView = () => {
  const record: TaskDefinition = useRecordContext();
  return (
    <Paper sx={{ padding: '10px' }} variant='outlined'>
      <Typography sx={{ marginBottom: '10px', textAlign: 'center' }} variant='h6'>
        Schritte
      </Typography>
      {record.stages.map((stage, index) => (
        <TaskStageView key={stage.name} index={index} stage={stage} />
      ))}
    </Paper>
  );
};
