import React, { useMemo, useCallback, useState } from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  ImageInput,
  ImageField,
  useDataProvider,
  SaveContextProvider,
  useNotify,
  UserIdentity,
} from 'react-admin';
import { Card, Grid, CardHeader } from '@mui/material';
import { useProfile } from '../../contexts/profileContext/ProfileContext';

export const ProfileEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const { profile, isLoading, updateProfile } = useProfile();

  const handleSave = useCallback(
    (data: Record<string, unknown>) => {
      setSaving(true);
      dataProvider.updateUserProfile(data).then(
        () => {
          setSaving(false);
          // eslint-disable-next-line camelcase
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
          updateProfile(data as UserIdentity);
        },
        () => {
          setSaving(false);
          notify('ra.page.error', {
            type: 'warning',
          });
        },
      );
    },
    [dataProvider, notify, updateProfile],
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave],
  );

  if (isLoading) {
    return null;
  }

  return (
    <Card>
      <CardHeader title='Anpassung Profil' />
      <Grid container>
        <Grid item>
          <SaveContextProvider value={saveContext}>
            <SimpleForm onSubmit={handleSave} record={profile ? profile : {}}>
              <TextInput
                source='fullName'
                label='Vollständiger Name'
                validate={required()}></TextInput>
              <ImageInput
                source='avatarObject'
                label='Profilbild'
                accept='image/*'
                placeholder={<p>Hier klicken zur Bildauswahl.</p>}
                validate={required()}>
                <ImageField source='src' title='title' />
              </ImageInput>
            </SimpleForm>
          </SaveContextProvider>
        </Grid>
      </Grid>
    </Card>
  );
};
