import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const SciDataViewsPage = () => {
  return (
    <>
      <Typography m={2} variant='h2'>
        Data Views
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Card>
            <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography m={1} variant='h4'>
                Hello World
              </Typography>
              <Typography variant='body1'>Some Description</Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Button startIcon={<EditIcon />} size='small' variant='contained'>
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card>
            <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Typography m={1} variant='h4'>
                Hello World
              </Typography>
              <Typography variant='body1'>Some Description</Typography>
            </CardContent>
            <CardActions sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Button startIcon={<EditIcon />} size='small' variant='contained'>
                Edit
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SciDataViewsPage;
