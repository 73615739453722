import {
  ArrayInput,
  Edit,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import TaskDefinition from '../businessEntities/TaskDefinition';
import { useStageContext } from '../../contexts/stageContext/StageContextProvider';

const addStateForStages = (data: TaskDefinition) => {
  data.stages.forEach((stage) => (stage.state = stage.state ?? 0));
  return data;
};

const TaskDefinitionEdit = () => {
  const { getAllStageComponents } = useStageContext();
  return (
    <Edit redirect='list' transform={addStateForStages}>
      <SimpleForm>
        <Typography variant='h5'>Edit Task Definition</Typography>
        <TextInput label='Id' source='id' />
        <TextInput label='Name' source='name' />
        <ArrayInput source='stages'>
          <SimpleFormIterator>
            <TextInput source='name' />
            <SelectInput source='guiComponent' choices={getAllStageComponents()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default TaskDefinitionEdit;
