import { defaultTheme } from 'react-admin';

const SciLightTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: '#3078BE',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#3078BE',
      dark: '#D9D9D9',
      contrastText: '#fff',
    },
    mode: 'light' as const, // Switching the dark mode on is a single property value change.
  },
  typography: {
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 240, // The default value is 240
    closedWidth: 55, // The default value is 55
  },
  overrides: {
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .RaSidebar-drawerPaper': {},
          '& .RaSidebar-fixed': {
            zIndex: 1200,
            width: 76,
          },
          '&.MuiDrawer-docked .MuiPaper-root': {
            width: 76,
          },
        },
      },
    },
    RaMenuItemCategory: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    RaLogin: {
      stylesOverride: {
        root: {
          backgroundImage: 'url("ppi.webp") !important', // Background image on the login
        },
      },
    },
  },
};

const SciDarkTheme = {
  ...SciLightTheme,
  palette: {
    primary: {
      main: '#08afe1',
    },
    secondary: {
      main: '#08afe1',
    },
    mode: 'dark' as const, // Switching the dark mode on is a single property value change.
  },
};

export { SciLightTheme, SciDarkTheme };
