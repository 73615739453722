import { UserIdentity } from 'react-admin';
import { FirebaseAuthProvider, RAFirebaseOptions } from 'react-admin-firebase';
import firebaseProviderConfig from './firebaseProviderConfig.json';

const firebaseAuthProvider = FirebaseAuthProvider(
  firebaseProviderConfig.firebaseConfig,
  firebaseProviderConfig.firebaseOptions as RAFirebaseOptions,
);

const FirebaseAuthProviderRbac = {
  ...firebaseAuthProvider,
  getPermissions: () =>
    Promise.resolve([
      { action: ['read', 'list'], resource: 'Tasks' },
      { action: '*', resource: 'taskDefinitions' },
      { action: '*', resource: '*' },
    ]),
  getIdentity: () =>
    new Promise<UserIdentity>((resolve, reject) => {
      try {
        const storedProfile = localStorage.getItem('profile');
        if (storedProfile == null) {
          reject('error');
        } else {
          const { id, fullName, avatar } = JSON.parse(storedProfile);
          resolve({ id, fullName, avatar });
        }
      } catch (error) {
        reject(error);
      }
    }),
};

export default FirebaseAuthProviderRbac;
