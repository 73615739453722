import { Typography } from '@mui/material';
import {
  Create,
  DateInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useDataProvider,
} from 'react-admin';
import { WizardForm, WizardFormStep } from '@react-admin/ra-form-layout';
import { useWatch } from 'react-hook-form';
import TaskDefinition from '../businessEntities/TaskDefinition';
import StageDefinition from '../businessEntities/StageDefinition';

/*
// TODO erde: Fix this stuff
const handleCheckboxChange = () => {
  console.log('Selected');
};

const TaskTypeGrid = () => {
  const { allChoices, selectedChoices, isLoading } = useChoicesContext();

  return (
    <Grid container spacing={2} m={0} pt={1}>
      {allChoices.map((choice) => (
        <Card sx={{ margin: '5px' }} key={choice.name}>
          <CardActionArea sx={{ display: 'flex', padding: '0 5px' }}>
            <AutoAwesomeMotionIcon />
            <CardContent>
              <Typography component='div' variant='body1'>
                {choice.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Grid>
  );
};
*/

const FinalStepContent = () => {
  const values = useWatch({
    name: ['id', 'name', 'endDate'],
  });
  return values?.length > 0 ? (
    <>
      <Typography>title: {values[1]}</Typography>
      <Typography>Task Type: {values[0]}</Typography>
      <Typography>endDate: {values[2]}</Typography>
    </>
  ) : null;
};

const TaskCreate = () => {
  const dataProvider = useDataProvider();
  const prepareTask = (record: TaskDefinition) => {
    return new Promise((resolve, reject) => {
      dataProvider.getOne<TaskDefinition>('taskDefinitions', { id: record.task }).then((result) => {
        record.stages = result.data.stages;
        record.stages.forEach((stage: StageDefinition) => (stage.state = 0));
        if (result.data.panelInformation) {
          record.panelInformation = result.data.panelInformation;
        }
        resolve(record);
      }, reject);
    });
  };

  return (
    <Create redirect='list' transform={prepareTask}>
      <WizardForm>
        <WizardFormStep label='Select Task Type'>
          <Typography variant='h6'>Select the task type</Typography>
          <ReferenceInput source='task' reference='taskDefinitions' perPage={100}>
            <SelectInput optionText='name' optionValue='id' />
          </ReferenceInput>
        </WizardFormStep>
        <WizardFormStep label='Set Task parameters'>
          <TextInput source='name' />
          <DateInput source='endDate' />
        </WizardFormStep>
        <WizardFormStep label='Summary'>
          <FinalStepContent />
        </WizardFormStep>
      </WizardForm>
    </Create>
  );
};

export default TaskCreate;
