import React, { useMemo, useState, createContext, useContext, useEffect } from 'react';
import { useAuthProvider, useDataProvider, UserIdentity } from 'react-admin';

type ProfileContextType = {
  profile: UserIdentity;
  profileVersion: number;
  isLoading: boolean;
  updateProfile: (identity: UserIdentity) => void;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: { id: '', fullName: '' },
  profileVersion: 0,
  isLoading: true,
  updateProfile: () => ({}),
});

const ProfileContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const [profile, setProfile] = useState<UserIdentity>({ id: '', fullName: '' });
  const [isLoading, setIsLoading] = useState(true);

  const updateProfile = (identity: UserIdentity) => {
    setIsLoading(true);
    if (identity?.avatarObject?.rawFile) {
      const updatedIdentity = identity;
      convertFileToBase64(identity.avatarObject.rawFile).then(
        (avatar) => {
          updatedIdentity.avatar = avatar === null ? undefined : avatar;
          setProfile(updatedIdentity);
          setIsLoading(false);
          dataProvider.updateUserProfile(updatedIdentity);
        },
        () => setIsLoading(false),
      );
    } else {
      setProfile(identity);
      dataProvider.updateUserProfile(profile);
    }
  };

  // Load data from authProvider initially
  useEffect(() => {
    authProvider.getIdentity?.().then((identity: UserIdentity) => {
      setProfile(identity);
      setProfileVersion((currentVersion) => currentVersion + 1);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({
      profile,
      profileVersion,
      isLoading,
      updateProfile,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, profile, profileVersion],
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

const convertFileToBase64 = (file: File) =>
  new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string | null);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export default ProfileContextProvider;

export const useProfile = () => useContext(ProfileContext);
