import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import {
  DeleteWithConfirmButton,
  EditButton,
  ListButton,
  Show,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import TaskDefinition from '../businessEntities/TaskDefinition';
import ViewModeContext, { ViewMode } from '../../contexts/viewModeContext/ViewModeContext';
import StageContextProvider, {
  useStageContext,
} from '../../contexts/stageContext/StageContextProvider';
import TaskContextProvider from '../../contexts/taskContext/TaskContextProvider';

const TaskStages = () => {
  const record: TaskDefinition = useRecordContext();
  const { currentStageIndex, setCurrentStageIndex, getCurrentStageGuiComponent } =
    useStageContext();

  let StageComponent = getCurrentStageGuiComponent();

  // This method handles the switch to the next stage
  const handleNext = () => {
    setCurrentStageIndex(currentStageIndex + 1);
    StageComponent = getCurrentStageGuiComponent();
  };

  // This method handles the switch to the previous stage
  const handleBack = () => {
    setCurrentStageIndex(currentStageIndex - 1);
    StageComponent = getCurrentStageGuiComponent();
  };

  const StageStepperControls = () => {
    if (currentStageIndex < record.stages.length - 1) {
      return (
        <Box>
          <Button variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
            Continue
          </Button>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </Box>
      );
    } else if (currentStageIndex === record.stages.length - 1) {
      return (
        <Box>
          <Button variant='contained' onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
            Finish
          </Button>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
          <ListButton sx={{ mt: 1, mr: 1 }} />
        </Box>
      );
    }
  };

  const StageStepper = () => (
    <Stepper activeStep={currentStageIndex} alternativeLabel>
      {record.stages.map((stage) => (
        <Step key={stage.name}>
          <StepLabel>{stage.name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <Box sx={{ p: 3 }}>
      <StageStepper />
      <Box sx={{ pt: 2, pb: 2 }}>
        <StageComponent />
      </Box>
      <StageStepperControls />
    </Box>
  );
};

const TaskShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteWithConfirmButton />
    <ListButton />
  </TopToolbar>
);

const TaskShow = () => (
  <Show actions={<TaskShowActions />}>
    <ViewModeContext.Provider value={ViewMode.Show}>
      <TaskContextProvider>
        <StageContextProvider>
          <TaskStages />
        </StageContextProvider>
      </TaskContextProvider>
    </ViewModeContext.Provider>
  </Show>
);

export default TaskShow;
