import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import mdFile from './doc.md';

const SciHandbuchPage = () => {
  return (
    <Card>
      <CardHeader title='Benutzerhandbuch' />
      <CardContent>{mdFile}</CardContent>
    </Card>
  );
};
export default SciHandbuchPage;
