import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import {
  DeleteWithConfirmButton,
  Edit,
  ListButton,
  ShowButton,
  TopToolbar,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import TaskDefinition from '../businessEntities/TaskDefinition';
import TaskContextProvider from '../../contexts/taskContext/TaskContextProvider';
import ViewModeContext, { ViewMode } from '../../contexts/viewModeContext/ViewModeContext';
import StageContextProvider, {
  useStageContext,
} from '../../contexts/stageContext/StageContextProvider';

const TaskStages = () => {
  const record: TaskDefinition = useRecordContext();
  const { currentStageIndex, setCurrentStageIndex, getCurrentStageGuiComponent } =
    useStageContext();
  const [update, { isLoading, error }] = useUpdate();

  if (record === null || record.stages === undefined) return null;
  if (error) {
    return <p>ERROR</p>;
  }

  let StageComponent = getCurrentStageGuiComponent();

  // This method handles the switch to the next stage and updates the database
  const handleNext = () => {
    const updatedStages = record.stages;
    setCurrentStageIndex(
      (() => {
        if (currentStageIndex < updatedStages.length) {
          updatedStages[currentStageIndex].state = 2;
        }
        if (currentStageIndex + 1 < updatedStages.length) {
          updatedStages[currentStageIndex + 1].state = 1;
        }
        return currentStageIndex + 1;
      })(),
    );

    update('Tasks', { id: record.id, data: { stages: updatedStages }, previousData: record });
    StageComponent = getCurrentStageGuiComponent();

    // Save business data here
  };

  // This method handles the switch to the previous stage and updates the database
  const handleBack = () => {
    const updatedStages = record.stages;
    setCurrentStageIndex(
      (() => {
        if (currentStageIndex >= updatedStages.length) {
          updatedStages[updatedStages.length - 1].state = 1;
        } else {
          updatedStages[currentStageIndex].state = 0;
          updatedStages[currentStageIndex - 1].state = 1;
        }
        return currentStageIndex - 1;
      })(),
    );

    update('Tasks', { id: record.id, data: { stages: updatedStages }, previousData: record });
    StageComponent = getCurrentStageGuiComponent();
  };

  // This method handles the reset of the task and sets the current stage to 0
  const handleReset = () => {
    const updatedStages = record.stages;
    setCurrentStageIndex(0);
    updatedStages.forEach((stage) => (stage.state = 0));
    update('Tasks', { id: record.id, data: { stages: updatedStages }, previousData: record });
  };

  const StageStepperControls = () => {
    if (currentStageIndex < record.stages.length - 1) {
      return (
        <Box>
          <Button
            disabled={isLoading}
            variant='contained'
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}>
            Continue
          </Button>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </Box>
      );
    } else if (currentStageIndex === record.stages.length - 1) {
      return (
        <Box>
          <Button
            disabled={isLoading}
            variant='contained'
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}>
            Finish
          </Button>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button disabled={currentStageIndex === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
          <ListButton sx={{ mt: 1, mr: 1 }} />
        </Box>
      );
    }
  };

  const StageStepper = () => (
    <Stepper activeStep={currentStageIndex} alternativeLabel>
      {record.stages.map((stage) => (
        <Step key={stage.name}>
          <StepLabel>{stage.name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <Box sx={{ p: 3 }}>
      <StageStepper />
      <Box sx={{ pt: 2, pb: 2 }}>
        <StageComponent />
      </Box>
      <StageStepperControls />
    </Box>
  );
};

const TaskEditActions = () => (
  <TopToolbar>
    <ShowButton />
    <DeleteWithConfirmButton />
    <ListButton />
  </TopToolbar>
);

const TaskEdit = () => (
  <Edit actions={<TaskEditActions />}>
    <ViewModeContext.Provider value={ViewMode.Edit}>
      <TaskContextProvider>
        <StageContextProvider>
          <TaskStages />
        </StageContextProvider>
      </TaskContextProvider>
    </ViewModeContext.Provider>
  </Edit>
);

export default TaskEdit;
