import React, { useContext, useState, useEffect } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import TaskDefinition, {
  TaskDataType,
  PanelInformationType,
} from '../../entities/businessEntities/TaskDefinition';

type TaskContextType = {
  record: TaskDefinition;
  saveTaskData(key: string, value: unknown): void;
  updatePanelInformation(name: string, value: string): void;
};

const TaskContext = React.createContext({
  record: { id: '', name: '', stages: [] },
  saveTaskData: () => {
    return;
  },
  updatePanelInformation: () => {
    return;
  },
} as TaskContextType);

const TaskContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const dataProvider = useDataProvider();
  const record: TaskDefinition = useRecordContext();

  // Necessary to access the updated record locally without fetching
  const [cachedRecord, setCachedRecord] = useState<TaskDefinition>(record);
  const [taskData, setTaskData] = useState<TaskDataType | undefined>(cachedRecord.taskData);
  const [panelInformation, setPanelInformation] = useState<PanelInformationType | undefined>(
    cachedRecord.panelInformation,
  );

  // Helper method to update the cached record
  const updateCachedRecord = (data: Record<string, unknown>) => {
    const updatedRecord = cachedRecord;
    Object.entries(data).forEach(([key, value]) => {
      updatedRecord[key] = value;
    });
    setCachedRecord(updatedRecord);
  };

  // Update cached record on record change
  useEffect(() => {
    setCachedRecord(record);
  }, [record]);

  // Update database on value change
  useEffect(() => {
    // Only perform update if values did actually change
    if (
      record.id === '' ||
      (JSON.stringify(cachedRecord.taskData) === JSON.stringify(taskData) &&
        JSON.stringify(cachedRecord.panelInformation) === JSON.stringify(panelInformation))
    ) {
      return;
    }

    updateCachedRecord({ taskData, panelInformation });
    dataProvider.update('Tasks', {
      id: cachedRecord.id,
      data: { taskData, panelInformation },
      previousData: record,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData, panelInformation]);

  const taskContextData: TaskContextType = {
    record: cachedRecord,
    saveTaskData: (key: string, value: unknown) => {
      setTaskData(() => {
        if (taskData) {
          return { ...taskData, ...{ [key]: value } };
        } else {
          return { [key]: value };
        }
      });
    },
    updatePanelInformation: (name: string, value: string) => {
      setPanelInformation(() => {
        if (panelInformation) {
          const index = panelInformation.findIndex((element) => element.name === name);
          const updatedPanelInformation = panelInformation;
          index === -1
            ? updatedPanelInformation.push({ name, value })
            : (updatedPanelInformation[index].value = value);
          return updatedPanelInformation;
        } else {
          return [{ name, value }];
        }
      });
    },
  };
  return <TaskContext.Provider value={taskContextData}>{children}</TaskContext.Provider>;
};

export default TaskContextProvider;

/**
 * This hook allows to access the {@link TaskContext}.
 */
export const useTaskContext: () => TaskContextType = () => useContext(TaskContext);
