import { Box, Grid, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import {
  Datagrid,
  DateField,
  FunctionField,
  LinearProgress,
  LinearProgressProps,
  List,
  ShowButton,
  TextField,
  useRecordContext,
} from 'react-admin';
import TaskDefinition from '../businessEntities/TaskDefinition';
import { TaskStagesView } from './TaskStageView';

const TaskPanel = () => {
  const record: TaskDefinition = useRecordContext();
  return (
    <Grid sx={{ padding: '10px' }} container spacing={3}>
      <Grid item xs={2}>
        <Paper sx={{ padding: '10px' }} variant='outlined'>
          <Typography sx={{ marginBottom: '10px', textAlign: 'center' }} variant='h6'>
            Information
          </Typography>
          <Table size='small'>
            <TableBody>
              {record.panelInformation?.map(({ name, value }) => (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <TaskStagesView />
      </Grid>
    </Grid>
  );
};

const LinearProgressWithLabel = (props: LinearProgressProps) => {
  const record: TaskDefinition = useRecordContext();
  let value = 0;
  if (record.stages.length > 0) {
    value = Math.round(
      (record.stages.filter((stage) => stage.state === 2).length / record.stages.length) * 100,
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <LinearProgress variant='determinate' value={value} {...props} />
      </Box>
      <Box sx={{ marginLeft: '10px' }}>
        <Typography variant='body2' color='text.secondary'>{`${value}%`}</Typography>
      </Box>
    </Box>
  );
};

const TaskList = () => (
  <List title='Tasks'>
    <Datagrid bulkActionButtons={false} expand={<TaskPanel />} rowClick='expand'>
      <TextField source='name' />
      <TextField source='task' />
      <FunctionField label='Progress' render={() => <LinearProgressWithLabel />} />
      <DateField source='endDate' />
      <ShowButton />
    </Datagrid>
  </List>
);

export default TaskList;
