import React, { useState } from 'react';
import { useGetIdentity, useAuthenticated } from 'react-admin';
import {
  CardHeader,
  Card,
  Box,
  Grid,
  Avatar,
  CardContent,
  Button,
  Typography,
} from '@mui/material';
import SciSettings from './SciSettings';
import { ProfileEdit } from './ProfileEdit';

export const ProfileShow = () => {
  useAuthenticated();
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const [showProfilDataEdit, setShowProfilDataEdit] = useState(false);

  const clickHandlerEdit = () => {
    showInitialSettings();
  };

  const showInitialSettings = () => {
    setShowProfilDataEdit(!showProfilDataEdit);
  };

  const showAvatar =
    identity == undefined || identity?.avatar == undefined ? (
      <Avatar />
    ) : (
      <div>
        <Avatar src={`data:image/png;base64, ${identity.avatar.split(',')[1]}`} alt='Avatar' />
      </div>
    );

  if (identityLoading) {
    return (
      <Card>
        <CardHeader title='Profil' />
        <CardContent>loading ...</CardContent>
      </Card>
    );
  } else {
    if (showProfilDataEdit) {
      return <ProfileEdit />;
    } else {
      return (
        <Card>
          <CardHeader title='Einstellungen' />
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Box sx={{ p: 4 }}>
                    <Typography variant='h6'> Profil</Typography>
                    <Box sx={{ p: 4 }}>
                      <Grid container>
                        <Grid item>
                          <Box sx={{ p: 1 }}>
                            Name: {identity?.fullName?.length == 0 ? 'Batman' : identity?.fullName}{' '}
                            ( {identity?.id} )
                          </Box>
                          <Box sx={{ p: 1 }}>{showAvatar}</Box>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                      <Box sx={{ p: 2 }} />
                      <Button variant='outlined' onClick={clickHandlerEdit}>
                        Profil anpassen
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <SciSettings />
              </Grid>
            </Grid>
          </Box>
        </Card>
      );
    }
  }
};
