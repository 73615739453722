import * as React from 'react';
import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useAuthProvider, useTranslate } from 'ra-core';
import {
  Tooltip,
  IconButton,
  Menu,
  Button,
  Avatar,
  PopoverOrigin,
  useMediaQuery,
  Theme,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Logout, UserMenuClasses, UserMenuProps } from 'react-admin';
import { useProfile } from '../contexts/profileContext/ProfileContext';

/**
 * The UserMenu component renders a Mui Button that shows a Menu.
 * It accepts children that must be Mui MenuItem components.
 *
 * @example
 * import { Logout, UserMenu, useUserMenu } from 'react-admin';
 * import MenuItem from '@mui/material/MenuItem';
 * import ListItemIcon from '@mui/material/ListItemIcon';
 * import ListItemText from '@mui/material/ListItemText';
 * import SettingsIcon from '@mui/icons-material/Settings';

 * const ConfigurationMenu = React.forwardRef((props, ref) => {
 *     const { onClose } = useUserMenu();
 *     return (
 *         <MenuItem
 *             ref={ref}
 *             {...props}
 *             to="/configuration"
 *             onClick={onClose}
 *         >
 *             <ListItemIcon>
 *                 <SettingsIcon />
 *             </ListItemIcon>
 *             <ListItemText>Configuration</ListItemText>
 *         </MenuItem>
 *     );
 * });
 *
 * export const MyUserMenu = () => (
 *     <UserMenu>
 *         <ConfigurationMenu />
 *         <Logout />
 *     </UserMenu>
 * );
 * @param props
 * @param {ReactNode} props.children React node/s to be rendered as children of the UserMenu. Must be Mui MenuItem components
 * @param {string} props.className CSS class applied to the MuiAppBar component
 * @param {string} props.label The label of the UserMenu button. Accepts translation keys
 * @param {Element} props.icon The icon of the UserMenu button.
 *
 */
const SciUserMenu = (props: UserMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const translate = useTranslate();
  const { profile, isLoading } = useProfile();
  const authProvider = useAuthProvider();

  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const {
    children = authProvider ? <Logout /> : null,
    className,
    label = 'ra.auth.user_menu',
    icon = defaultIcon,
  } = props;

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  if (!children) return null;
  const open = Boolean(anchorEl);

  return (
    <Root className={className}>
      {isLargeEnough && !isLoading && profile?.fullName ? (
        <Button
          aria-label={label && translate(label, { _: label })}
          className={UserMenuClasses.userButton}
          color='inherit'
          startIcon={
            profile.avatar ? (
              <Avatar
                className={UserMenuClasses.avatar}
                src={profile.avatar}
                alt={profile.fullName}
              />
            ) : (
              icon
            )
          }
          onClick={handleMenu}>
          {profile.fullName}
        </Button>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-haspopup={true}
            color='inherit'
            onClick={handleMenu}
            size='large'>
            {!isLoading && profile?.avatar ? (
              <Avatar
                className={UserMenuClasses.avatar}
                src={profile.avatar}
                alt={profile.fullName}
              />
            ) : (
              icon
            )}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id='menu-appbar'
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        open={open}
        onClose={handleClose}>
        {children}
      </Menu>
    </Root>
  );
};

SciUserMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  icon: PropTypes.node,
};

const PREFIX = 'RaUserMenu';

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${UserMenuClasses.userButton}`]: {
    textTransform: 'none',
  },

  [`& .${UserMenuClasses.avatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const defaultIcon = <AccountCircle />;

const AnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const TransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export default SciUserMenu;
