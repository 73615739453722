import * as React from 'react';
import { useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const UrlIframeDialogField = (props: { source: string; label: string; buttonText: string }) => {
  const record = useRecordContext();
  if (!record) return null;
  return <UrlIFrameDialog source={record.url} label={props.label} buttonLabel={props.buttonText} />;
};

export default UrlIframeDialogField;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export function UrlIFrameDialog(prop: { source: string; label: string; buttonLabel: string }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='outlined' onClick={handleClickOpen}>
        {prop.buttonLabel}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {prop.label}
            </Typography>
            <Button color='inherit' onClick={handleClose}>
              Schließen
            </Button>
          </Toolbar>
        </AppBar>
        <iframe title='T1' id='T1' height='100%' width='100%' src={prop.source} />
      </Dialog>
    </div>
  );
}
