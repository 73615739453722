import React from 'react';
import { AppBarClasses, Logout, LogoutProps, MenuItemLink, SidebarToggleButton } from 'react-admin';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { useProfile } from '../contexts/profileContext/ProfileContext';
import { AppBar, Theme, Toolbar, useMediaQuery } from '@mui/material';
import SciUserMenu from './SciUserMenu';

const SciLogoutButton = (props: LogoutProps) => <Logout {...props} icon={<ExitToAppIcon />} />;

const CustomSciAppBar = () => {
  const { profileVersion } = useProfile();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <AppBar>
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        disableGutters
        variant={isXSmall ? 'regular' : 'dense'}
        className={AppBarClasses.toolbar}>
        <SidebarToggleButton className={AppBarClasses.menuButton} />
        <SciUserMenu key={profileVersion}>
          <MenuItemLink to='/profile' primaryText='Einstellungen' leftIcon={<SettingsIcon />} />
          <SciLogoutButton />
        </SciUserMenu>
      </Toolbar>
    </AppBar>
  );
};

export default CustomSciAppBar;
