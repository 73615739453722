import * as React from 'react';

import i18nProvider from './providers/I18nProvider/I18nProvider';
import dataProvider from './providers/SciFirebaseDataProvider';
import authProvider from './providers/SciFirebaseAuthProvider';
import loginPage from './pages/SciLoginPage/SciLoginPage';
import dashboardPage from './pages/SciDashboardPage/SciDashboardPage';
import { Admin } from '@react-admin/ra-enterprise';
import { EventList } from '@react-admin/ra-audit-log';
import { CustomRoutes, EditGuesser } from 'react-admin';
import { Resource } from '@react-admin/ra-rbac';
import { Route } from 'react-router';
import SciSignUpPage from './pages/SciLoginPage/SciSignUpPage';
import SciNoPasswordPage from './pages/SciLoginPage/SciNoPasswordPage';
import SciLayout from './layout/SciLayout';
import TaskList from './entities/task/TaskList';
import TaskEdit from './entities/task/TaskEdit';
import TaskShow from './entities/task/TaskShow';
import TaskDefinitionCreate from './entities/taskDefinition/TaskDefinitionCreate';
import TaskDefinitionEdit from './entities/taskDefinition/TaskDefinitionEdit';
import TaskDefinitionList from './entities/taskDefinition/TaskDefinitionList';
import TaskCreate from './entities/task/TaskCreate';
import SciDataViewsPage from './pages/SciDataViewsPage/SciDataViewsPage';
import DataArrayIcon from '@mui/icons-material/DataArray';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { spkDarkTheme, spkLightTheme } from './layout/themes/SparkassenTheme';
import SciHandbuchPage from './pages/SciServicePages/SciHandbuchPage';

import { ProfileShow } from './pages/SciSettingsPage/ProfileShow';
import { ProdukteCreate, ProdukteEdit, ProdukteList } from './entities/businessEntities/produkte';

const App = () => (
  <Admin
    i18nProvider={i18nProvider}
    dashboard={dashboardPage}
    loginPage={loginPage}
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={spkLightTheme}
    lightTheme={spkLightTheme}
    darkTheme={spkDarkTheme}
    layout={SciLayout}>
    <CustomRoutes noLayout>
      <Route path='/register' element={<SciSignUpPage />} />
      <Route path='/nopassword' element={<SciNoPasswordPage />} />
    </CustomRoutes>

    <CustomRoutes>
      <Route path='/profile' element={<ProfileShow />} />
      <Route path='/dataviews' element={<SciDataViewsPage />} />
      <Route path='/handbuch' element={<SciHandbuchPage />} />
    </CustomRoutes>

    <Resource name='products' list={ProdukteList} />

    <Resource
      name='Tasks'
      options={{ label: 'Aufgaben' }}
      list={TaskList}
      edit={TaskEdit}
      create={TaskCreate}
      show={TaskShow}
      icon={TaskAltIcon}
    />

    <Resource
      name='taskDefinitions'
      options={{ label: 'Prozesse' }}
      list={TaskDefinitionList}
      edit={TaskDefinitionEdit}
      create={TaskDefinitionCreate}
      icon={PlaylistAddCheckIcon}
    />

    <Resource
      name='demoDataView'
      options={{ label: 'Admin' }}
      list={TaskDefinitionList}
      edit={TaskDefinitionEdit}
      create={TaskDefinitionCreate}
      icon={DataArrayIcon}
    />
    <Resource name='events' options={{ label: 'Audit Log' }} list={EventList} />
    <Resource name='produkte' list={ProdukteList} edit={ProdukteEdit} create={ProdukteCreate} />
  </Admin>
);

export default App;
