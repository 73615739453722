import { Typography } from '@mui/material';
import {
  ArrayInput,
  Create,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useStageContext } from '../../contexts/stageContext/StageContextProvider';

const TaskDefinitionCreate = () => {
  const { getAllStageComponents } = useStageContext();
  return (
    <Create redirect='list'>
      <SimpleForm>
        <Typography variant='h5'>Create Task Definition</Typography>
        <TextInput label='Id' source='id' />
        <TextInput label='Name' source='name' />
        <ArrayInput source='stages'>
          <SimpleFormIterator>
            <TextInput source='name' />
            <SelectInput source='guiComponent' choices={getAllStageComponents()} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source='panelInformation'>
          <SimpleFormIterator>
            <TextInput source='name' />
            <TextInput source='value' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default TaskDefinitionCreate;
